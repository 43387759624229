import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import { toTitleCase } from "../../../../util/services";
import SellerProfile from "../SellerProfile/SellerProfile";
import { Cascader } from "antd";
import {
  DEFAULT_CURRENCY,
  IS_DOMESTIC_MODE,
  IS_INTERNATIONAL_MODE,
} from "../../../../constants";
import { SLAllCitiesDetails } from "../../../../DummyData/SLAllCitiesDetails";
import { chatActions } from "../../../../modules/ChatPage/ducks";

class ProductRightBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  visitStoreHandler = () => {
    const { product } = this.props;
    var sellerID =
      product && product.data && product.data.data && product.data.data.seller
        ? product.data.data.seller.id
        : null;
    this.props.history.push(`/seller/${sellerID}`);
  };

  locationOnChangeHandler = (value, selectedOptions) => {
    
  };

  locationFilterHandler = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  render() {
    const { product, shippingCost, selectedShippingMethod } = this.props;

    const { data } = product.data;
    let shippingCostData =
      shippingCost && shippingCost.data && shippingCost.data.data;
    const currentRoute = this.props.match.url;

    return (
      <React.Fragment>
        <div className="padding-sin-pro-details hide-web-view-hr">
          <hr />
        </div>
        {IS_DOMESTIC_MODE && (
          <div className="hide-mobile-view">
            <div className="mb-2">Location :</div>
            <Cascader
              options={SLAllCitiesDetails}
              onChange={this.locationOnChangeHandler}
              placeholder="Please select"
              showSearch={this.locationFilterHandler}
            />
          </div>
        )}
        <div className="mt-2 padding-sin-pro-details">
          <SellerProfile
            product={product}
            visitStoreHandler={this.visitStoreHandler}
            currentRoute={currentRoute}
            selectedSellerHandler={this.props.chatActions.selectedSellerHandler}
          />
        </div>
        <div className="mt-4 padding-sin-pro-details">
          <h6 className="mb-3">
            <i className="fas fa-box"></i> Package Details
          </h6>
          <div>
            Type :
            <span style={{ color: "#8a8a8a" }}>
              {` ${
                data &&
                data.package_details &&
                toTitleCase(data.package_details.package_type)
              }`}
            </span>
          </div>
          <div>
            Weight :
            <span style={{ color: "#8a8a8a" }}>
              {` ${
                data && data.package_details && data.package_details.weight
              }`}
            </span>
          </div>
          <div>
            Length :
            <span style={{ color: "#8a8a8a" }}>
              {` ${
                data && data.package_details && data.package_details.length
              }`}
            </span>
          </div>
          <div>
            Height :
            <span style={{ color: "#8a8a8a" }}>
              {` ${
                data && data.package_details && data.package_details.height
              }`}
            </span>
          </div>
          <div>
            Width :
            <span style={{ color: "#8a8a8a" }}>
              {` ${data && data.package_details && data.package_details.width}`}
            </span>
          </div>
        </div>

        <div className="mt-4 padding-sin-pro-details">
          <h6 className="mb-3">
            <i className="fas fa-shield-alt"></i> Return & Warranty
          </h6>
          <div>
            Warranty :
            <span style={{ color: "#8a8a8a" }}>{` ${
              data && data.warranty
            }`}</span>
          </div>
          <div>
            Return Accepted :
            <span style={{ color: "#8a8a8a" }}>{` ${
              data && data.return_accepted_method
            }`}</span>
          </div>
        </div>

        <div className="mt-4 padding-sin-pro-details">
          <h6 className="mb-3">
            <i className="fas fa-shipping-fast"></i>{" "}
            {IS_INTERNATIONAL_MODE ? "Shipping" : "Delivery"} Details
          </h6>
          {selectedShippingMethod?.rate_additional_item &&
            +selectedShippingMethod?.rate_additional_item !== 0 && (
              <div>
                Additional {IS_INTERNATIONAL_MODE ? "Shipping" : "Delivery"}{" "}
                Cost :
                <span
                  style={{ color: "#8a8a8a" }}
                >{`${DEFAULT_CURRENCY} ${selectedShippingMethod?.rate_additional_item}`}</span>
              </div>
            )}
          <div>
            {IS_INTERNATIONAL_MODE ? "Shipping" : "Delivery"} Handling Days :
            <span style={{ color: "#8a8a8a" }}>{` ${
              data && data.shipping_handling_days
            }`}</span>
          </div>
          <div>
            Estimated {IS_INTERNATIONAL_MODE ? "Shipping" : "Delivery"} Days :
            <span style={{ color: "#8a8a8a" }}>{` ${
              shippingCostData && shippingCostData.estimated_time
            }`}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCart: state.Cart.myCart,
    product: state.Cart.product,
    shippingCost: state.Cart.shippingCost,
    userSelections: state.Cart.userSelections,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    chatActions: bindActionCreators(chatActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductRightBar)
);
